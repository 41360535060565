import {
  Button,
  ButtonRole,
  ButtonTargetKind,
  ButtonSize,
} from "@components/Button";
import { ObservableIntersection } from "@components/ObservableIntersection";
import { MAX_TEXT_INPUT_WIDTH } from "@components/TextInput";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { FormEventHandler, useState, useCallback } from "react";

import { AsyncFunction } from "@every.org/common/src/helpers/types";

import { buttonCss as makeButtonCss } from "src/styles/button";
import { lightBgThemeCss } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import {
  spacing,
  verticalStackCss,
  horizontalStackCss,
} from "src/theme/spacing";
import { longH2Css } from "src/theme/text";

export const FormBody = styled.section`
  flex-grow: 1; /* form body expands to height of parent */

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > :not(:last-child) {
    margin-bottom: ${spacing.m};
  }
`;

const HeadingText = styled.h1`
  margin-bottom: ${spacing.xl};
`;

const SubHeadingText = styled.h2`
  ${longH2Css};
  margin-bottom: ${spacing.xl};
`;

const Back = styled(Button)``;

const FormElem = styled("form")``;

export enum Align {
  CENTER = "CENTER",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

const stickActionsCss = [
  css`
    position: sticky;
    bottom: 0;
    width: calc(100% + ${spacing.l} * 2);
    padding: ${spacing.s} ${spacing.l};
    margin: -${spacing.s} -${spacing.l};
  `,
  cssForMediaSize({
    min: MediaSize.MEDIUM_SMALL,
    css: css`
      width: calc(100% + ${spacing.xl} * 2);
      padding: ${spacing.s} ${spacing.xl};
      margin: -${spacing.s} -${spacing.xl};
    `,
  }),
];

const Actions = styled.section<{
  align: Align;
  stickyActions: boolean;
  checkScreenSize: boolean;
}>`
  ${horizontalStackCss.xs};
  align-items: center; /* vertically center children */
  justify-content: ${({ align }): string => {
    switch (align) {
      case Align.CENTER:
        return "center";
      case Align.LEFT:
        return "flex-start";
      case Align.RIGHT:
        return "flex-end";
    }
  }};

  ${({ stickyActions, checkScreenSize }) =>
    stickyActions
      ? checkScreenSize
        ? cssForMediaSize({ max: MediaSize.MEDIUM, css: stickActionsCss })
        : stickActionsCss
      : false}

  ${Back} {
    margin-right: auto;
  }
`;

type SubmitFormHandler = (
  ...params: Parameters<FormEventHandler<HTMLFormElement>>
) => void;
export type SubmitFunction =
  | SubmitFormHandler
  | AsyncFunction<SubmitFormHandler>;

export interface FormProps
  extends Omit<
    React.DetailedHTMLProps<
      React.FormHTMLAttributes<HTMLFormElement>,
      HTMLFormElement
    >,
    "onSubmit"
  > {
  "data-tname": string;
  className?: string;

  headerContent?: React.ReactNode;
  headingText?: React.ReactNode;
  subHeadingText?: React.ReactNode;
  footerContent?: React.ReactNode;

  /**
   * Aligns the action button(s) LEFT, CENTER, RIGHT.
   * Defaults to RIGHT.
   */
  alignActions?: Align;

  /**
   * What to render in the back button
   */
  backButtonContent?: React.ReactNode;
  /**
   * What to render in the submit button
   *
   * @default "Submit"
   */
  submitButtonContent?: React.ReactNode;
  /**
   * Form handler for submission.
   *
   * - If async, disables the submit button until the promise resolves
   * - If not present, then submit button will not be shown and the form will be
   *   rendered as a div, not a form element
   */
  onSubmit?: SubmitFunction;
  /**
   * Callback function to call once the onSubmit callback has been completed.
   * Useful for doing things like navigate the page after the form is done
   * submitting.
   *
   * These two functions cannot be combined, since after submission is
   * complete, the form updates its own state to reset the submitting button
   * state. This means that if you try to navigate away from the page in the
   * onSubmit function, we'll try to set the state on an unmounted component
   * and throw a React error.
   */
  onSubmitComplete?: () => void;

  onBack?: () => void;

  /**
   * Extra buttons to render alongside submission
   *
   * - For each action listed, will display a secondary call to action button
   *   with the inputted contents
   * - Useful for cancel buttons
   * - If onSubmit also isn't present, this does nothing
   */
  otherActions?: {
    buttonContent: React.ReactNode;
    callback: () => void | Promise<void>;
    disabled?: boolean;
    key: string;
    "data-tname": string;
  }[];

  /**
   * If true, doesn't render padding or border on the form
   *
   * - Border isn't rendered ever for small screens
   * - useful for elements like modals that already have borders
   *
   * @default false
   */
  borderless?: boolean;
  /**
   * Limit form width to input width
   * @default false
   */
  limitToInputWidth?: boolean;

  disableSubmit?: boolean;

  hideActions?: boolean;
  hideNextButton?: boolean;
  stickyActions?: boolean;
  checkScreenSize?: boolean;
  formRef?: React.RefObject<HTMLFormElement | null>;
}

/**
 * Renders a standard form matching our common styles and content sections
 *
 * Since it is intended to enforce visual consistency around the app, it is
 * prescriptive - headingText, for example, is only consistent if it's
 * predominantly text, and button rendering is controlled, for example.
 */
export const Form: React.FCC<FormProps> = ({
  headerContent,
  headingText,
  className,
  subHeadingText,
  footerContent,
  backButtonContent,
  submitButtonContent = "Submit",
  alignActions = Align.RIGHT,
  onSubmit,
  disableSubmit,
  onBack,
  onSubmitComplete,
  otherActions,
  children,
  limitToInputWidth = false,
  borderless = false,
  "data-tname": dataTname,
  hideActions = false,
  stickyActions = false,
  checkScreenSize = false,
  hideNextButton,
  formRef,
  ...rest
}) => {
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = useCallback(
    async (...params: Parameters<Exclude<typeof onSubmit, undefined>>) => {
      if (!onSubmit) {
        return;
      }

      setSubmitting(true);
      try {
        params[0].preventDefault();
        await onSubmit(...params);
        setSubmitting(false);
        onSubmitComplete && onSubmitComplete();
      } catch {
        setSubmitting(false);
      }
    },
    [onSubmit, onSubmitComplete]
  );
  const [showActionsShadow, setShowActionsShadow] = useState(false);

  const textOnlyButtonCss = cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: makeButtonCss(ButtonRole.TEXT_ONLY, ButtonSize.MEDIUM),
  });

  const primaryButtonCss = cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: makeButtonCss(ButtonRole.PRIMARY, ButtonSize.MEDIUM),
  });

  const secondaryButtonCss = cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: makeButtonCss(ButtonRole.SECONDARY, ButtonSize.MEDIUM),
  });

  const formContent = (
    <React.Fragment>
      {headerContent && <header>{headerContent}</header>}
      <FormBody>
        <div>
          {headingText && <HeadingText>{headingText}</HeadingText>}
          {subHeadingText && <SubHeadingText>{subHeadingText}</SubHeadingText>}
          <div>{children}</div>
        </div>
        {onSubmit && (
          <Actions
            stickyActions={stickyActions}
            checkScreenSize={checkScreenSize}
            align={alignActions}
            css={[
              showActionsShadow &&
                stickyActions &&
                css`
                  ${lightBgThemeCss};
                  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.3);
                `,
              hideActions
                ? css`
                    display: none;
                  `
                : undefined,
            ]}
          >
            {onBack && (
              <Back
                data-tname="form--back"
                role={ButtonRole.TEXT_ONLY}
                size={ButtonSize.SMALL}
                onClick={{ kind: ButtonTargetKind.FUNCTION, action: onBack }}
                buttonCss={textOnlyButtonCss}
              >
                {backButtonContent || "Back"}
              </Back>
            )}
            {otherActions &&
              otherActions.map(
                ({
                  "data-tname": actionDataTname,
                  buttonContent,
                  callback,
                  disabled,
                  key,
                }) => (
                  <Button
                    data-tname={actionDataTname}
                    key={key}
                    role={ButtonRole.SECONDARY}
                    size={ButtonSize.SMALL}
                    onClick={{
                      kind: ButtonTargetKind.FUNCTION,
                      action: callback,
                    }}
                    disabled={disabled}
                    buttonCss={secondaryButtonCss}
                  >
                    {buttonContent}
                  </Button>
                )
              )}
            {!hideNextButton && (
              <Button
                data-tname={`${dataTname}-submit`}
                role={ButtonRole.PRIMARY}
                size={ButtonSize.SMALL}
                onClick={{ kind: ButtonTargetKind.SUBMIT }}
                disabled={disableSubmit || submitting}
                submitting={submitting}
                buttonCss={primaryButtonCss}
              >
                {submitButtonContent}
              </Button>
            )}
          </Actions>
        )}
        <ObservableIntersection onChange={setShowActionsShadow} />
      </FormBody>
      {footerContent && <footer>{footerContent}</footer>}
    </React.Fragment>
  );

  return (
    <FormElem
      css={[
        verticalStackCss.l,
        limitToInputWidth &&
          css`
            max-width: ${MAX_TEXT_INPUT_WIDTH};
          `,
      ]}
      className={className}
      onSubmit={onSubmit ? handleSubmit : undefined}
      data-tname={dataTname}
      ref={formRef}
      {...rest}
    >
      {formContent}
    </FormElem>
  );
};
